$fontFamily: 'Noto Sans JP', sans-serif;

// FontSize
$fontSize0: 0;
$fontSize12: 12px;
$fontSize14: 14px;
$fontSize16: 16px;

// FontWeight
$fontWeight400: 400;
$fontWeight500: 500;
$fontWeight700: 700;

// LineHeight
$lineHeight18: 18px;
$lineHeight21: 21px;
$lineHeight24: 24px;
$lineHeight40: 40px;
