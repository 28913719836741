@use './typography';
@import 'ress';

html,
body {
  padding: 0;
  margin: 0;
  font-family: typography.$fontFamily;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
